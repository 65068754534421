.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 4rem);
  width: 100%;
}

.box {
  width: 40%;
  height: fit-content;
  border: 1px solid #e0e3e6;
  border-radius: 12px;
  background-color: #ffffff;
}
.verificationContainer {
  padding: 24px;
  color: #40454a;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  color: #171725;
  text-wrap: no-wrap;
}
.verification {
  padding: 24px 24px 28px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}
.para {
  color: #999;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  width: 100%;
}
.verifyOtpBtn {
  border-radius: 8px;
  border: 1px solid #0084ef;
  background: #0084ef;
  width: 100%;
  padding: 14px 14px;
  color: #ffffff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}
.otpFlex {
  color: #999;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: -20px;
}
.footerPara {
  font-size: 14px;
  line-height: 20px;
  border: '1px solid black';
}

@media (max-width: 767px) {
  .box {
    width: 90%;
    margin-top: auto;
    margin-bottom: auto;
    position: fixed;
  }
  .verificationContainer {
    padding: 20px;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
  }
  .verification {
    padding: 20px;
    gap: 1.25rem;
  }
  .para {
    font-size: 12px;
    line-height: 18px;
  }
  .verifyOtpBtn {
    padding: 14px 10px;
    font-size: 16px;
  }
  .otpFlex {
    /* flex-direction: column; */
    font-size: 14px;
    line-height: 16px;
  }
  .footerPara {
    padding: 16px 20px;
    font-size: 12px;
    line-height: 16px;
  }
}
