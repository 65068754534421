.roundDots {
  width: 0.375rem;
  height: 0.375rem;
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 50%;
  background: black;
}
.questionContainer {
  display: flex;

  justify-content: space-between;
  align-items: center;
}
.accordionItem {
  padding: 0.5rem 0.75rem 0.5rem 0rem;
  width: 23.95rem;
  border-radius: 0.5rem;
  background: #f5f5f5;
  margin-left: 0.8rem;
}
.caretDown {
  height: 1.25rem;
  width: 1.25rem;
}
.questionMain {
  display: flex;
  gap: 10px;
}
.allQuestionsContainer {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  /* width: 22.125rem; */
  margin-bottom: 1rem;
}
.modalTitle {
  /* color: #363535;
font-family: Inter;
font-size: 0.875rem;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.0105rem; */

  font-size: 0.875rem;
  color: #363535;
  font-style: normal;
  font-family: Inter;
  font-weight: 60;
  line-height: normal;
  letter-spacing: 0.0105rem;
  width: 100%;
}
.question {
  color: #363535;
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.009rem;
}
.ulList {
  margin-bottom: 0rem;
}
.ulListItem {
  color: #363535;
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.009rem;
}
.dialogClass {
  display: flex;
  justify-content: end;
  align-items: end;
  height: 82% !important;
  margin-right: 1rem;
}
.answer {
  color: #454545;
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.009rem;
  margin-left: 2rem;
  margin-top: 0.5rem;
}
.videoConatiner {
  display: flex;
  width: 10.3125rem;
  height: 6.25rem;
  padding: 2.125rem 0rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: rgba(69, 69, 69, 0.5);
  margin-left: 3rem;
  cursor: pointer;
  margin-bottom: 0.25rem;
  border-radius: 0.5rem;
}

.modal {
  position: fixed;
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: end;
  justify-content: end;
  padding-right: 1rem;
  height: 100%;
}

.modalContent {
  /* background-color: #fff; 
border-radius: 8px;
box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); */
  padding: 0.88rem 0.38rem 0.38rem 0.38rem;
  max-width: 26%;
  border-radius: 0.75rem;
  background: #fff;
  box-shadow: 4px 4px 16px 0px rgba(69, 69, 69, 0.2);
  margin-bottom: 12%;
}

.modalHeader {
  /* display: flex;
justify-content: space-between;
align-items: center;
padding-left: 2rem;
padding-right:1rem; */

  border-bottom: unset;
  padding-bottom: unset;
  display: flex;
  padding: 1rem 2rem 10px 1rem;
}

/* .modalTitle {
margin: 0;
font-size: 1.2rem;
font-weight: bold;
}
*/
.closeButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  font-size: 1.5rem;
  color: #999;
}

.closeButton:hover {
  color: #333;
}
.contentClass {
  border-radius: 0.75rem;
  box-shadow: 4px 4px 16px 0px rgba(69, 69, 69, 0.2);
  width: 75%;
  margin-bottom: 4%;
}
.answerHeading {
  color: #0084ef;
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.009rem;
  padding-left: 1rem;
  margin-bottom: 0.25rem;
}

.modalBody {
  max-height: 67vh; /* Adjust the height as per your requirement */
  overflow-y: auto;
  padding: 0;
}
.modal-dialog {
  height: 85%;
}
